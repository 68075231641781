export interface IGetObjectData {
  query: EngineQuery;
}

// TODO all types here should be generated from the go engine services
// instead of being manually maintained

// ========= INPUT =============

export enum ResultType {
  String = 'string',
  Json = 'json',
  Decimal = 'decimal',
  Int = 'int',
  Float = 'float',
  Date = 'date',
  Time = 'time',
  Timestamp = 'timestamp',
  TimestampTZ = 'timestamp_tz',
  Boolean = 'boolean',
}

/**
 * Field query object. Used to identify fields from database.
 */
export interface FieldQuery {
  fieldKey: string;
}

/**
 * Grouped dimension query object. Used to identify grouped dimensions defined in DVE.
 */
export interface GroupedDimensionQuery {
  group: {
    fieldKeys: string[];
  };
}

/**
 * Item query object. Used to identify items defined in DVE.
 */
export interface ItemQuery {
  itemKey: string;
}

/**
 * Field definition, and a legacy way of identifying fields.
 */
export interface QueryFieldDefinition {
  schema?: string;
  view: string;
  name: string;
}

export type InterRecordType = 'value' | 'diff' | 'ratio' | 'growth';

export type AdvancedOperationAggregation = 'SUM' | 'AVG' | 'MIN' | 'MAX';

export interface InterRecord {
  type: InterRecordType;
  offset: number;
}

export interface InterGroup {
  type: InterRecordType;
  aggregation: AdvancedOperationAggregation;
  currentByDimIdxs?: number[];
  previousByDimIdxs?: number[];
}

export interface GeoSimplify {
  tolerance: number;
}

export interface MovingCalculation {
  aggregation: AdvancedOperationAggregation;
  showIfNotEnoughValues: boolean;
  windowBegin: number;
  windowEnd: number;
}

export interface RunningTotalCalculation {
  aggregation: AdvancedOperationAggregation;
  partitionByDimIdxs?: number[];
  orders?: Partial<FieldOrder>[];
}

export interface RankCalculation {
  dense: boolean;
  sortDirection: 'asc' | 'desc';
  byDimIdxs?: number[];
}

export type NestedCalculationType = 'accumulatedGrowth' | 'value' | 'ratio';

export interface NestedAggregationWithAdditionalDimensions {
  aggregation: AdvancedOperationAggregation;
  additionalDimensions: DimensionQuery[];
}

export interface NestedCalculation {
  type: NestedCalculationType;
  calculation?: CalculationQuery;
  additionalDimensions?: DimensionQuery[];
  aggregation?: AdvancedOperationAggregation;
  lhs?: NestedAggregationWithAdditionalDimensions;
  rhs?: NestedAggregationWithAdditionalDimensions;
}

/**
 * Item definition, legacy way of identifying items.
 */
export interface QueryItemDefinition {
  itemName: string;
}

export type FieldIdentifiers = FieldQuery | QueryFieldDefinition;
export type ItemIdentifiers = ItemQuery | QueryItemDefinition;

/**
 * Dimension properties.
 */
export interface Dimension {
  itemName?: string;
  resultType?: ResultType;
  dateLevel?: DateLevel; // excluded with datePart
  datePart?: DatePart; // excluded with dateLevel
  topDimension?: boolean;
  /**
   * Now we are doing transformation of these values from QueryFilterValue to FilterValue
   * on Daedalus side, but it should be reflected in the interfaces if we want to continue doing so.
   *
   * For now, we will keep it as is, but we should consider fixing this in the future.
   */
  expandedValues?: QueryFilterValue[];
  expandAll?: boolean;
  format?: QueryFormatDefinition;
  suppressNull?: boolean;
  bucketing?: Bucketing;
  label?: string;
}

export type DimensionWithItemKey = Dimension & ItemQuery;
export type DimensionWithItemDefinition = Dimension & QueryItemDefinition;
export type DimensionWithFieldKey = Dimension & FieldQuery;
export type DimensionWithFieldDefinition = Dimension & QueryFieldDefinition;
export type GroupedDimension = Dimension & GroupedDimensionQuery;

export type DimensionQuery =
  | DimensionWithItemKey
  | DimensionWithItemDefinition
  | DimensionWithFieldKey
  | DimensionWithFieldDefinition
  | GroupedDimension;

/**
 * Measure properties.
 */
export interface Measure<T = QueryFilterDefinition> {
  itemName?: string;
  /**
   * These two optional properties, fieldKeys and fields, should be removed after we
   * have proper support for item queries in the engine based on itemKeys. For now,
   * we are not sure how the interfaces will exactly look like on the engine side,
   * so we will keep these as optionals, and just overwrite them as required in the
   * MeasureWithFieldKey and MeasureWithFieldDefinition types.
   */
  fieldKeys?: string[];
  fields?: FieldIdentifiers[];
  label?: string;
  filters?: T[];
  aggregation: Aggregation['name'];
  resultType?: ResultType;
  distinct?: boolean;
  delimiter?: string;
  withinGroup?: QueryFieldOrder;
  partitionBy?: FieldIdentifiers[];
  orderBy?: QueryFieldOrder[];
  format?: QueryFormatDefinition;
  total?: boolean;
  fns?: DefinedFunction[];
  params?: DefinedParam[];
  interRecord?: InterRecord;
  geoSimplify?: GeoSimplify;
  movingCalculation?: MovingCalculation;
  runningTotalCalculation?: RunningTotalCalculation;
  interGroup?: InterGroup;
  rankCalculation?: RankCalculation;
}

export type MeasureWithItemKey<T = QueryFilterDefinition> = Measure<T> & ItemQuery;
export type MeasureWithItemDefinition<T = QueryFilterDefinition> = Measure<T> & QueryItemDefinition;
export type MeasureWithFieldKeys<T = QueryFilterDefinition> = Measure<T> & { fieldKeys: string[] };
export type MeasureWithFieldDefinitions<T = QueryFilterDefinition> = Measure<T> & { fields: QueryFieldDefinition[] };

export type MeasureQuery<T = QueryFilterDefinition> =
  | MeasureWithItemKey<T>
  | MeasureWithItemDefinition<T>
  | MeasureWithFieldKeys<T>
  | MeasureWithFieldDefinitions<T>;

/**
 * Calculation properties.
 */
export interface Calculation<T = QueryFilterDefinition> {
  itemName?: string;
  label?: string;
  // Since introduction of nested calculations this is not required anymore.
  calculation?: string;
  measures?: MeasureQuery<T>[];
  format?: QueryFormatDefinition;
  fns?: DefinedFunction[];
  params?: DefinedParam[];
  filterParams?: DefinedParam[];
  namedParameters?: NamedParameters;
  interRecord?: InterRecord;
  geoSimplify?: GeoSimplify;
  movingCalculation?: MovingCalculation;
  interGroup?: InterGroup;
  rankCalculation?: RankCalculation;
  runningTotalCalculation?: RunningTotalCalculation;
  nested?: NestedCalculation;
}

export type CalculationWithItemKey<T = QueryFilterDefinition> = Calculation<T> & ItemQuery;
export type CalculationWithItemName<T = QueryFilterDefinition> = Calculation<T> & QueryItemDefinition;

export type CalculationQuery<T = QueryFilterDefinition> =
  | CalculationWithItemKey<T>
  | CalculationWithItemName<T>
  | Calculation<T>;

/**
 * The main query object used to query data from the engine.
 */
export interface Query<T = QueryFilterDefinition | QueryPostFilterDefinition> {
  dimensions?: DimensionQuery[];
  measures?: (MeasureQuery | CalculationQuery)[];
  calculations?: CalculationQuery[];
  filters?: T[];
  filterGroup?: FilterGroup<T>;
  postFilterGroup?: FilterGroup<T>;
  limit?: number;
  offset?: number;
  orders?: FieldOrder[];
  queryTimeout?: number;
  queryMode?: QueryMode;
  formatResults?: boolean;
  timeTravel?: TimeTravel;
  topDimLimit?: number;
  topDimOffset?: number;
  totals?: boolean;
  histogram?: boolean;
  outerViews?: { schema?: string; view: string }[];
  variables?: Record<string, number | string | boolean>;
  topN?: TopN;
  hideZeroOrNULLMeasureValues?: boolean;
}

export interface FilterGroup<T = QueryFilterDefinition | QueryPostFilterDefinition> {
  filterGroups?: FilterGroup<T>[];
  or?: boolean;
  filters?: T[];
}

export interface EngineQuery extends Query<QueryEngineFilterDefinition> {
  databaseID: DatabaseInfo['id'];
}

export type QueryFieldOrder = FieldIdentifiers & { order: FieldOrder['direction'] };

export interface TopN {
  numberOfValues: number;
  dimensionIdx?: number;
  calculationIdx?: number;
  calculationDirection?: 'asc' | 'desc';
  calculation?: CalculationQuery;
  others?: TopNOthers;
}

export interface TopNOthers {
  show?: boolean;
  sortDirection?: 'asc' | 'desc';
  valueString?: string;
  valueDate?: string;
  valueInt?: number;
  valueFloat?: number;
  valueBool?: boolean;
}

export interface Bucketing {
  bucketNo?: boolean;
  outerBuckets?: boolean;
  buckets?: number;
  maxValue?: number;
  minValue?: number;
}

export interface DefinedFunction {
  name: string;
}

export interface DefinedParam {
  value: string;
  name: string;
  dataType: 'string' | 'int' | 'float' | 'boolean' | 'date';
}

export interface NamedParameters {
  formula: Record<string, string | number | boolean>;
  filter: Record<string, string | number | boolean>;
}

export interface Field {
  key: string;
  schema?: string;
  view: string;
  name: string;
  aggregators: Aggregation[];
  dataType: FieldDataType;
  isDimension?: boolean;
  isTopDimension?: boolean;
  isMeasure?: boolean;
  // daedalus property to distinct field defined as a manual data
  isManualData?: boolean;
}

export interface QueryResultField {
  name: string;
  hasNullable: boolean;
  hasLength: boolean;
  hasPrecisionScale: boolean;
  nullable: boolean;
  length: number;
  databaseType: string;
  precision: number;
  scale: number;
  dataType: string;
  isDimension: boolean;
  isTopDimension: boolean;
  isMeasure: boolean;
}

export interface QueryFormatDefinition {
  /**
   * @note Excel pattern format
   * https://support.microsoft.com/en-us/office/number-format-codes-5026bbd6-04bc-48cd-bf33-80f18b4eae68
   */
  pattern?: string;
  thousandSep?: string;
  decimalSep?: string;
}

export interface QueryOptions {
  ignoreGlobalSelection?: boolean;
}

export interface QueryRequest {
  requestID: string;
  query: Query;
}

export interface FieldOrder {
  columnIdx: number;
  direction: 'asc' | 'desc';
  visibleOrder?: number;
}

export enum QueryMode {
  NORMAL = 'normal',
  RECORD_LEVEL = 'recordlevel',
  HIERARCHY = 'hierarchy',
  PIVOT = 'pivot',
}

export interface ExecuteSQLDao {
  datasourceID: string;
  statement: string;
  limit?: number;
}

export interface CallProcedureDao {
  datasourceID: string;
  procedure: string;
  params: unknown[];
  schema: string;
}

export interface EngineResponse<T> {
  response: T;
  correlationID: string;
}

export interface ViewMetadata {
  schema?: string;
  view: string;
}

// ========= OUTPUT =========

export enum FieldType {
  STRING = 'string',
  INT = 'int',
  REAL = 'real',
  FLOAT = 'float',
  DATE = 'date',
  BOOLEAN = 'boolean',
  DECIMAL = 'decimal',
  JSON = 'json',
  TIME = 'time',
  TIMESTAMP = 'timestamp',
  TIMESTAMP_TZ = 'timestamp_tz',
  GROUPED_DIMENSION = 'grouped_dimension',
  GEOGRAPHY = 'geography',
}

export enum DatabaseItemType {
  DIMENSION = 'dimension',
  MEASURE = 'measure',
  CALCULATION = 'calculation',
  QUERY = 'query',
  FILTER = 'filter',
}

export interface DatabaseItem {
  key: string;
  id: string;
  name: string;
  description?: string;
  type: DatabaseItemType;
  // dimension is returned with field definition and key
  dimension?: DimensionWithFieldDefinition & DimensionWithFieldKey & GroupedDimension;
  // dimension is returned with field definitions and array of field keys
  measure?: MeasureWithFieldDefinitions & MeasureWithFieldKeys;
  calculation?: Calculation;
}

export interface Data {
  [index: number]: (string | number | boolean | null)[];
}

export interface QueryResponse {
  correlationID: string;
  response: QueryResult;
  telemetry: {
    duration: number;
    start: number;
  };
}

export class QueryInsights {
  databaseID?: string;
  queryID: string;
  startTime: number;
}

export class QueryInsightsResponse {
  joinIssue: boolean;
  unionIssue: boolean;
  memoryIssue: boolean;
  pruningIssue: boolean;
  cacheUsed: boolean;
  memoryDataQuery: boolean;
  creditsBestCase: number;
  creditsWorstCase: number;
  creditsFairValue: number;
  rowsScanned: number;
}

export interface BuildQueryResponse {
  response: BuildQueryResult;
  telemetry: {
    duration: number;
    start: number;
  };
}

export interface QueryErrorResponse {
  code: string;
  details: unknown;
  id: string;
  localeArgs: unknown;
  message: string;
  statusCode: number;
}

export interface ObjectDataTip {
  from: string[];
  to: string[];
  type: number;
  message: string;
}

export interface ObjectWarning {
  actions: ObjectDataTip[];
  identifiers: string[];
  message: string;
  type: number;
}

export interface QueryResult {
  queryID: string;
  queryLink: string;
  fields: QueryResultField[];
  data: Data;
  grouping?: Record<number, number[]>;
  meta: { rows: number; cols: number };
  statement: string;
  viewStates: ViewState[];
  totals?: Data;
  warnings?: ObjectWarning[];
}

export interface FieldResponse {
  name: string;
  hasNullable: boolean;
  hasLength: boolean;
  hasPrecisionScale: boolean;
  nullable: boolean;
  length: number;
  databaseType: string;
  precision: number;
  scale: number;
  dataType: string;
  isDimension: boolean;
  isTopDimension: boolean;
  isMeasure: boolean;
  format: QueryFormatDefinition;
}

export interface BuildQueryResult {
  statement: string;
  viewStates: ViewState[];
}

export enum ViewStateShowAll {
  DISABLED_TICKED = 0,
  ENABLED_UNTICKED = 1,
  ENABLED_TICKED = 2,
}

export interface ViewState {
  schema?: string;
  view: string;
  showAll?: ViewStateShowAll;
}

export interface GetDataConfig {
  ignoreFilters?: boolean;
}

// ====== SCHEMA ============

export enum FieldDataType {
  String = 'string',
  Int = 'int',
  Float = 'float',
  Decimal = 'decimal',
  Date = 'date',
  Timestamp = 'timestamp',
  TimestampTZ = 'timestamp_tz',
  Boolean = 'boolean',
  Geography = 'geography',
}

// field date types
export const FieldTimestampTypes: FieldDataType[] = [FieldDataType.Timestamp, FieldDataType.TimestampTZ];
export const FieldDateTypes: FieldDataType[] = [FieldDataType.Date, ...FieldTimestampTypes];

// item date types
export const ItemTimestampTypes: ResultType[] = [ResultType.Timestamp, ResultType.TimestampTZ];
export const ItemDateTypes: ResultType[] = [ResultType.Date, ...ItemTimestampTypes];

export interface SchemaField {
  key?: string;
  name: string;
  dataType: string;
  source: string;
  sourceType: 'column' | 'expression';
  comment: string;
  id?: string;
}

export interface SchemaJoinInfo {
  condition: string;
  schemaName1: string;
  schemaName2: string;
  viewName1: string;
  viewName2: string;
}

export interface SchemaViewInfo {
  id: string;
  name: string;
  joinGroup?: number;
  source: string;
  sourceType: string;
  fields: SchemaField[];
}
export interface Schema {
  id: string;
  name: string;
  source: string;
  views: SchemaViewInfo[];
}

export interface Datasource {
  id: string;
  driverName: Vendor;
  authType: DatasourceAuthType;
}

export interface DatasourceUsers {
  users: DatasourceUser[];
}

export interface DatasourceUser {
  userID: string;
}

export interface DatabaseInfo {
  id: string;
  datasourceID: string;
  createdBy: string;
  joins: SchemaJoinInfo[];
  schemas: Schema[];
  items: DatabaseItem[];
  locale?: LocaleSettings;
  isDemo?: boolean;
  name?: string;
}

export enum DataType {
  ANYTYPE = 'anytype',
  STRING = 'string',
  NUMERIC = 'numeric',
  BOOLEAN = 'boolean',
  GEOGRAPHY = 'geography',
}

export enum ParamType {
  EXPRESSION = 'expression',
  NONE = 'none',
  LIST = 'list',
}

export interface Aggregation {
  cumulative: boolean;
  dataType: DataType;
  resultType: FieldType | 'incoming';
  delimiter: boolean;
  distinct: boolean;
  groupBy: boolean;
  ignoreNulls: boolean;
  name: string;
  orderBy: boolean;
  paramType: ParamType;
  partitionBy: boolean;
  rankRelated: boolean;
  withinGroup: boolean;
  common?: boolean;
  paramCountMin: number;
  paramCountMax: number;
  asterisk: boolean;
  rtd: boolean;
}

export enum DatePart {
  Year = 'year',
  Month = 'month',
  Day = 'day',
  DayOfWeekISO = 'dayofweekiso',
  DayOfYear = 'dayofyear',
  WeekISO = 'weekiso',
  Quarter = 'quarter',
  YearOfWeekISO = 'yearofweekiso',
  Hour = 'hour',
  Minute = 'minute',
  Second = 'second',
}

export enum DateLevel {
  Year = 'year',
  YearEnd = 'year-end',
  Quarter = 'quarter',
  QuarterEnd = 'quarter-end',
  Month = 'month',
  MonthEnd = 'month-end',
  Week = 'week',
  WeekEnd = 'week-end',
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
  Second = 'second',
}

export enum DynamicDateCalcRangeType {
  YearToDate = 'ytd',
  MonthToDate = 'mtd',
  QuarterToDate = 'qtd',
  WeekToDate = 'wtd',
  PreviousYearToDate = 'pytd',
  PreviousMonthToDate = 'pmtd',
  PreviousQuarterToDate = 'pqtd',
  PreviousWeekToDate = 'pwtd',
  Last12Months = 'l12m',
  Last90Days = 'l90d',
  Last30Days = 'l30d',
  Last7Days = 'l7d',
  CurrentYear = 'cy',
  CurrentQuarter = 'cq',
  CurrentMonth = 'cm',
  PreviousYear = 'py',
  PreviousMonth = 'pm',
  PreviousQuarter = 'pq',
  PreviousWeek = 'pw',
  Today = 'today',
  Yesterday = 'yesterday',
}

export enum DynamicDateCalcType {
  BeginningOfYear = 'boy',
  BeginningOfPreviousYear = 'bopy',
  BeginningOfMonth = 'bom',
  BeginningOfPreviousMonth = 'bopm',
  BeginningOfQuarter = 'boq',
  BeginningOfPreviousQuarter = 'bopq',
  BeginningOfWeek = 'bow',
  BeginningOfPreviousWeek = 'bopw',
  EndOfYear = 'eoy',
  EndOfMonth = 'eom',
  EndOfQuarter = 'eoq',
  EndOfWeek = 'eow',
  Today = 'today',
  Yesterday = 'yesterday',
}

export interface Capabilities {
  join: boolean;
  customQuery: boolean;
  measureFilter: boolean;
  filterOperators: string[];
  aggregateFunctions: Aggregation[];
  recordLevelMode: boolean;
  hierarchyMode: boolean;
  pivotMode: boolean;
  timeTravel: boolean;
  dateLevels: DateLevel[];
  dateParts: DatePart[];
  disallowDuplicates: boolean;
  allowInsert: boolean;
  inlineData: boolean;
  csvImport: boolean;
  calculations: boolean;
  executeProcedure: boolean;
}

export enum FilterOperator {
  Equals = 'equals',
  NotEquals = 'notEquals',
  InList = 'inList',
  NotInList = 'notInList',
  GT = 'gt',
  GTE = 'gte',
  LT = 'lt',
  LTE = 'lte',
  Set = 'set',
  NotSet = 'notSet',
  Between = 'between',
  NotBetween = 'notBetween',
  Like = 'like',
  NotLike = 'notLike',
  Contains = 'contains',
  NotContains = 'notContains',
  GeoIntersect = 'geointersects',
}

interface QueryFilterStringValue {
  dataType:
    | FilterValueDataType.Date
    | FilterValueDataType.Time
    | FilterValueDataType.Timestamp
    | FilterValueDataType.TimestampTZ
    | FilterValueDataType.String;
  value: string;
}

interface QueryFilterBooleanValue {
  dataType: FilterValueDataType.Boolean;
  value: boolean;
}

interface QueryFilterNumberValue {
  dataType: FilterValueDataType.Int | FilterValueDataType.Float | FilterValueDataType.Millisec;
  value: number;
}

export type QueryFilterValue = QueryFilterBooleanValue | QueryFilterNumberValue | QueryFilterStringValue;

export type QueryFilterValueSimple = boolean | string | number;
export type QueryFilterDefinition = FilterDefinition<
  QueryFilterValueSimple | QueryFilterValue | FilterValue | NullFilterValue
>;
export type QueryEngineFilterDefinition = FilterDefinition | FilterCalculationDefinition;
export type QueryPostFilterDefinition = FilterCalculationDefinition<
  QueryFilterValueSimple | QueryFilterValue | FilterValue | NullFilterValue
>;

/**
 * Temporary type to use in SelectionAPI instead of QueryFilterDefinition. It's required
 * because right now SelectionAPI can only work with name/view/schema information.
 * It should be removed after we will be able to obtain fieldsProvider in SelectionAPI which
 * will allow SelectionAPI to operate on fieldKey information.
 *
 * TODO remove this type after SelectionAPI refactoring
 */
export type SelectionQueryFilterDefinition = FilterWithFieldDefinition<
  QueryFilterValueSimple | QueryFilterValue | FilterValue | NullFilterValue
>;

export interface FilterProperties<T = FilterValue | NullFilterValue> {
  operator: FilterOperator;
  values: T[];
  dateLevel?: DateLevel;
  datePart?: DatePart;
  dynamicDate?: {
    offset: number;
    part: string;
  };
  dynamicDateCalc?: {
    type: DynamicDateCalcType;
  };
  dynamicDateCalcRange?: {
    type: DynamicDateCalcRangeType;
  };
  dynamicDateRange?: {
    offsetBegin: number;
    offsetEnd: number;
    part: string;
  };
}

export type FilterWithKey<T = FilterValue | NullFilterValue> = FilterProperties<T> & FieldQuery;
export type FilterWithFieldDefinition<T = FilterValue | NullFilterValue> = FilterProperties<T> & {
  field: QueryFieldDefinition;
};

export type FilterDefinition<T = FilterValue | NullFilterValue> = FilterWithKey<T> | FilterWithFieldDefinition<T>;

export type FilterCalculationDefinition<T = FilterValue | NullFilterValue> = {
  operator: FilterOperator;
  calculationNo: number;
  values: T[];
};

export enum FilterValueDataType {
  String = 'string',
  Int = 'int',
  Float = 'float',
  Date = 'date',
  Time = 'time',
  Timestamp = 'timestamp',
  TimestampTZ = 'timestamp_tz',
  Millisec = 'millisec',
  MillisecTZ = 'millisec_tz',
  Boolean = 'boolean',
  Decimal = 'decimal',
  Geography = 'geography',
}

export interface FilterValue {
  dataType: FilterValueDataType;
  valueString?: string;
  valueInt?: number;
  valueFloat?: number;
  valueDate?: string;
  valueTime?: string;
  valueTimeTZ?: string;
  valueDatetime?: string;
  valueDatetimeTZ?: string;
  valueMillisec?: number;
  valueBoolean?: boolean;
  valueGeoMinPoint?: string;
  valueGeoMaxPoint?: string;
}

export interface NullFilterValue {
  isNull: true;
}

export enum DatasourceAuthType {
  JWT_WITH_USER_SETTINGS = 'jwt_with_user_settings',
}

export enum Vendor {
  SNOWFLAKE = 'snowflake',
  GOOGLE_BIGQUERY = 'google_bigquery',
}

export interface QueryMetadata {
  id: QueryResult['queryID'];
  duration: QueryResponse['telemetry']['duration'];
  startTime: QueryResponse['telemetry']['start'];
  statement: QueryResult['statement'];
  queryLink: QueryResult['queryLink'];
  rows: QueryResult['meta']['rows'];
  vendor: Datasource['driverName'];
  warnings?: QueryResult['warnings'];
}

export interface LocaleSettings {
  number: NumberSettings;
  formats: FormatSettings;
  calendar: CalendarSettings;
  timezone: string;
}

export interface NumberSettings {
  decimalSep: string;
  thousandSep: string;
}

export interface FormatSettings {
  number: string;
  money: string;
  date: string;
  datetime: string;
  time: string;
}

export interface CalendarSettings {
  monthNames: string[];
  monthNamesAbbr: string[];
  dayNames: string[];
  dayNamesAbbr: string[];
}

export enum TimeTravelMode {
  OFFSET = 'offset',
  TIMESTAMP = 'timestamp',
}
export interface TimeTravel {
  mode: TimeTravelMode;
  value: number;
}

export interface EngineConfig {
  timeTravel?: TimeTravel;
}

export enum QueryExportType {
  xlsx = 'xlsx',
  csv = 'csv',
}
